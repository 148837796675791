import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"

import SEO from "../components/seo"

import { Background } from "../components/sections"
import AboutQuantic from "../components/sections/aboutQuantic"
import Contact from "../components/sections/contact"
import Products from "../components/sections/products"
import { Video } from "../components/video"
import { ParallaxProvider } from "react-scroll-parallax"
import "semantic-ui-css/semantic.min.css"

const IndexPage = () => {
  const { img } = useStaticQuery(graphql`
    {
      img: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <ParallaxProvider>
        <SEO title="Home" />
        <Background img={img} />
        <Video />
        <AboutQuantic />
        <Products />
        <Contact />
      </ParallaxProvider>
    </Layout>
  )
}

export default IndexPage
