import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Grid, Header, Item } from "semantic-ui-react"
import Image from "../image"
import { injectIntl } from "gatsby-plugin-intl"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const Products = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allProductsJson {
        nodes {
          id
          name
          description
          image
          price
          variety
          profile
          process
        }
      }
    }
  `)
  const products = data.allProductsJson.nodes
  return (
    <Container fluid content style={{ minHeight: "95vh" }}>
      <Grid textAlign="center" verticalAlign="middle">
        <Grid.Row style={{ margin: "1.5em 0" }}>
          <Grid.Column>
            <Header
              as="h1"
              content={intl.formatMessage({ id: "products.title" })}
              className="title"
              style={{ marginTop: "0.3rem", padding: "0.3em" }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} style={{ marginTop: "0" }}>
          <Grid.Column>
            <Carousel infiniteLoop showThumbs={false} showStatus={false}>
              {products.map(
                ({
                  id,
                  name,
                  description,
                  price,
                  image,
                  variety,
                  process,
                  profile,
                }) => (
                  <Item.Group key={id}>
                    <Item style={{ marginTop: "2.3em" }}>
                      <Item.Content style={{ margin: "2em 1.5em" }}>
                        <Item.Header
                          as="h1"
                          style={{
                            fontFamily: "Glacial",
                            marginTop: "2.3em",
                            fontSize: "2.5rem",
                          }}
                        >
                          {intl.formatMessage({
                            id: `products.P${id}.title`,
                          }) || name}
                        </Item.Header>
                        <Item.Meta>
                          <span>{`Plant variety: ${variety}`}</span>
                          <span>{`Price: $${price}`}</span>
                        </Item.Meta>
                        <Item.Description style={{ fontFamily: "Montserrat" }}>
                          <p style={{ fontSize: "1.6em" }}>
                            {intl.formatMessage({
                              id: `products.P${id}.text`,
                            }) || description}
                          </p>
                        </Item.Description>
                        <Item.Extra>
                          <Header
                            as="h3"
                            content="Aditional Details"
                            style={{ fontFamily: "Glacial" }}
                          />
                          <p>{`Profile: ${
                            intl.formatMessage({
                              id: `products.P${id}.characteristics`,
                            }) || profile
                          }`}</p>
                          <p>{`Extra: ${
                            intl.formatMessage({
                              id: `products.P${id}.extra`,
                            }) || process
                          }`}</p>
                        </Item.Extra>
                      </Item.Content>
                      <Item.Image size="medium" style={{ margin: "2em 1.5em" }}>
                        <Image
                          src={image}
                          alt={name}
                          width={200}
                          height={100}
                        />
                      </Item.Image>
                    </Item>
                  </Item.Group>
                )
              )}
            </Carousel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}
export default injectIntl(Products)
