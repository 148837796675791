import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Embed, Grid } from "semantic-ui-react"
export const Video = () => {
  const { img } = useStaticQuery(graphql`
    {
      img: file(relativePath: { eq: "DSC0346n5.JPG" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Container
      fluid
      content
      style={{
        backgroundColor: "#CBA06D",
        minHeight: "95vh",
      }}
    >
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column width={1} />
          <Grid.Column width={14}>
            <Embed
              style={{ marginTop: "1.4em" }}
              id="h8K8JH_m8zY"
              placeholder={img}
              source="youtube"
              autoplay
              active
            />
          </Grid.Column>
          <Grid.Column width={1} />
        </Grid.Row>
      </Grid>
    </Container>
  )
}
